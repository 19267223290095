import { LinearProgress, Paper } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { ArrowForward } from '@material-ui/icons'
import React from 'react'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'
import ProblemQuoteLines from 'views/ProblemQuoteLines'
import * as utils from 'utils/utils'

const useStyles = makeStyles(theme => ({
  root: {},
}))

export default function CompanyQuote({ nextPath }) {
  const classes = useStyles()
  const { divisionId, problemId } = useParams()
  const [item, setItem] = React.useState({})
  const { path, url } = useRouteMatch()
  const history = useHistory()

  const { data, error, isValidating, mutate } = useSWR(
    () =>
      problemId
        ? `Quotes?
            $filter=ProblemId eq ${problemId}&
            $expand=QuoteLines(
              $expand=ProductItem(
                $expand=Category($select=CategoryId,Description)
              )
            )
          `
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  const quote = data?.length ? data[0] : null
  const quoteId = quote?.QuoteId || undefined

  const handleChange = ({ target }) => {
    setItem(quote => ({ ...quote, [target.name]: target.value }))
  }

  const handleAddClick = async () => {
    await api.call(`Quotes`, {
      OperatingCompanyCode: 'A',
      DivisionId: divisionId,
      ProblemId: problemId,
      Description: `Service Report: ${problemId} - ${utils.date()}`,
      CustomerOrderReference: `Service Report: ${problemId} - ${utils.date()}`,
    })
    mutate()
  }

  if (!quote && isValidating)
    return (
      <Paper>
        <LinearProgress />
      </Paper>
    )

  if (!quoteId && !isValidating)
    return (
      <Box component={Paper} p={2} mt={2} alignContent="center">
        <Typography gutterBottom>
          There isn't a quote for this problem, click below to add one
        </Typography>
        <Button fullWidth onClick={handleAddClick}>
          Add quote
        </Button>
      </Box>
    )

  return (
    <>
      <Paper>
        <ProblemQuoteLines quoteId={quoteId} />
      </Paper>
      <Box mt={2} align="center">
        <Button onClick={() => history.push(nextPath)}>
          Next
          <ArrowForward />
        </Button>
      </Box>
    </>
  )
}
