import { Button, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import React from 'react'
import * as api from 'utils/api'
import { Link, Route, Switch, useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
})

export default function Login() {
  const classes = useStyles()
  const [value, setValue] = React.useState('')
  const history = useHistory()

  const handleClick = async () => {
    // window.API_PAT = value

    localStorage.setItem('pat', value)
    try {
      await api.call(`Divisions?$top=1`, null, 'GET')
      history.replace('/')
    } catch (e) {
      localStorage.removeItem('pat')
      alert('There was a problem with your key', e)
    }
  }

  return (
    <>
      <Typography gutterBottom align="center">
        Welcome, please provide your Prospectsoft Personal Access Token.
      </Typography>
      <TextField
        fullWidth
        onChange={(e, v) => setValue(e.target.value)}
        value={value}
        label="PAT token"
        margin="normal"
      />
      <Box align="center" my={4}>
        <Button variant="contained" onClick={handleClick}>
          Onwards!
        </Button>
      </Box>
      <Typography gutterBottom align="center">
        For help generating your Personal Access Token see{' '}
        <a
          href="http://docs.prospect365.com/en/articles/1689116-generating-a-pat-personal-access-token"
          target="_blank"
          rel="noopener"
        >
          this documentation
        </a>
        .
      </Typography>
    </>
  )
}
