import { Typography } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Add from '@material-ui/icons/Add'
import ListView from 'components/ListView'
import React from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'
import * as utils from 'utils/utils'
import { openStati, closedStati } from 'utils/constants'

export default function CompanyProblems() {
  const { divisionId } = useParams()
  const history = useHistory()

  // prettier-ignore
  const queryProblems = useSWR(
    () =>
      divisionId
        ? `Problems?
            $expand=Status($select=Description,EditAllowed,Outcome,RecallRequired,StatusCode)&
            $select=Status,Description,ProblemId,StatusId&
            $filter=StatusFlag ne 'D' and DivisionId eq ${divisionId} and Status/DeadFlag eq 0&$orderby=Status/DeadFlag asc,StatusId asc,StatusDetailId asc,Status/Description asc,Status/Outcome asc,LastUpdated desc&
            $top=10&
            $skip=0&
            $count=true`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  // prettier-ignore
  const queryOldProblems = useSWR(
    () =>
      divisionId
        ? `Problems?
            $expand=Status($select=Description,EditAllowed,Outcome,RecallRequired,StatusCode)&
            $select=Status,Description,ProblemId,StatusId&
            $filter=StatusFlag ne 'D' and DivisionId eq ${divisionId} and Status/DeadFlag eq 1&$orderby=Status/DeadFlag asc,StatusId asc,StatusDetailId asc,Status/Description asc,Status/Outcome asc,LastUpdated desc&
            $top=10&
            $skip=0&
            $count=true`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  const queryAddress = useSWR(
    () =>
      divisionId
        ? `Divisions(${divisionId})?$select=AddressId`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  // const pinitProblem = {
  //   Description: 'TEST PIPELINE',
  //   OwnerId: '_*_',
  //   ResponsibleUserId: '_*_',
  //   Type1Id: 'DELVRY',
  //   Type2Id: '090244',
  //   Type3Id: '6500f7',
  //   Type4Id: null,
  //   Type5Id: null,
  //   Analysis1Id: null,
  //   Analysis2Id: null,
  //   Analysis3Id: null,
  //   Analysis4Id: null,
  //   Analysis5Id: null,
  //   StatusId: 'AWTRET',
  //   DivisionId: 2380,
  //   PipelineId: '82e6f0',
  // }

  const initProblem = {
    PipelineId: '82e6f0', // always
    Type2Id: '0ea95c', // always
    Type1Id: 'TECHNI', // always / required
    Type3Id: '8d8db8', // sensible default
    SourceId: '92e350', // sensible default
    Description: 'New site visit', // below are required
    OwnerId: '_*_',
    ResponsibleUserId: '_*_',
    SearchCached: 0,
    DivisionId: divisionId,
    AddressId: queryAddress?.data?.[0]?.AddressId || null,
    StatusId: 'AWTRET',
  }

  const handleAddClick = async () => {
    const response = await api.call(`Problems`, initProblem, 'POST')
    queryProblems.mutate(data => [
      { ...response, Status: { Description: 'Requested' } },
      ...data,
    ])
    history.push(`/division/${divisionId}/problem/${response.ProblemId}/info`)
  }

  return (
    <>
      <Fab
        aria-label="add"
        onClick={handleAddClick}
        disabled={!initProblem?.AddressId}
      >
        <Add />
      </Fab>
      <Typography gutterBottom variant="h3">
        Open problems
      </Typography>
      <Paper component={Box} mb={4}>
        <ListView
          notFound="There are no open problems. Tap the + button to add a new problem."
          query={queryProblems}
          display={item => (
            <Link
              key={item?.ProblemId}
              to={`/division/${divisionId}/problem/${item?.ProblemId}/info`}
            >
              <ListItem>
                <ListItemText
                  primary={item?.Description}
                  secondary={item?.Status?.Description}
                />
              </ListItem>
            </Link>
          )}
        />
      </Paper>
      <Typography gutterBottom variant="h3">
        Completed problems
      </Typography>
      <Paper>
        <ListView
          notFound="There aren't any completed problems"
          query={queryOldProblems}
          display={item => (
            <Link
              key={item?.ProblemId}
              to={`/division/${divisionId}/problem/${item?.ProblemId}/info`}
            >
              <ListItem>
                <ListItemText
                  primary={item?.Description}
                  secondary={item?.Status?.Description}
                />
              </ListItem>
            </Link>
          )}
        />
      </Paper>
    </>
  )
}
