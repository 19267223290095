import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { ArrowForward } from '@material-ui/icons'
import Error from 'components/Error'
import Field from 'fields/Field'
import {
  FieldProvider,
  useFieldDispatch,
  useFieldState,
} from 'fields/FormFieldProvider'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'

export function ProblemInfo({ nextPath }) {
  const { divisionId, problemId } = useParams()
  const history = useHistory()
  // const [newProblem, setNewProblem] = React.useState({})
  const state = useFieldState()
  const dispatch = useFieldDispatch()

  const { data, error, isValidating, mutate } = useSWR(
    () => (problemId ? `Problems(${problemId})?$expand=Documents` : null),
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const updated = React.useRef(false)
  React.useEffect(() => {
    if (data)
      if (updated.current === false) {
        updated.current = true
        dispatch({ data: data[0] })
      }
  }, [data])

  if (error) return <Error error={error} />

  const handleSaveClick = async () => {
    const response = await api.call(`Problems(${problemId})`, state, 'PATCH')
    mutate()
    history.push(nextPath)
  }

  return (
    <>
      <Box component={Paper} p={2}>
        {!data && isValidating && <LinearProgress />}
        <Field text name="Description" label="Description" />
        {/* <Field textarea name="SituationSummary" label="Short job description" /> */}
        <Field
          dropdown
          name="Type3Id"
          label="Service type"
          values="Type3Id"
          labels="Description"
          // queryString={`ProblemTypes3?$select=Description,Type3Id`}
          queryString={`ProblemTypes3?$filter=Type3Id eq '8d8db8' or Type3Id eq '927d05' or Type3Id eq '28392f' or Type3Id eq 'a3dc06' or Type3Id eq 'WARANT'&$select=Description,Type3Id`}
          // helperText="Changing this will reset the checklist"
        />
        <Field
          dropdown
          name="SourceId"
          label="Source"
          values="Code"
          labels="Description"
          queryString={`ProblemSources?&$filter=Obsolete eq 0&$top=50&$count=true`}
        />
        {/* <Field
          dropdown
          name="StatusId"
          label="Status"
          values="StatusCode"
          labels="Description"
          // queryString={`ProblemStatus?$filter=StatusCode eq 'PRBGNA' or StatusCode eq 'FLDTOR' or StatusCode eq 'CLOSDS' or StatusCode eq 'PSDTOT'&select=StatusCode,Description `}
          queryString={`ProblemStatus?$select=Description,StatusCode,Obsolete`}
        /> */}
      </Box>
      <Box mt={2} align="center">
        <Button onClick={handleSaveClick}>
          Save and next <ArrowForward />
        </Button>
      </Box>
    </>
  )
}

export default props => (
  <FieldProvider>
    <ProblemInfo {...props} />
  </FieldProvider>
)

// const get =

// const fields = [
//   'AddressId',
//   'Description',
//   'InventoryId',
//   'PipelineId',
//   'ProblemId',
//   'SituationSummary',
//   'StatusDetailId',
//   'StatusId',
//   'Type1Id',
//   'Type2Id',
//   'Type3Id',
//   'Type4Id',
//   'Type5Id',
// ]

// const editableFields = [
//   'SourceId',
//   'Description',
//   'InventoryId', // ?
//   'SituationSummary',
//   'StatusId',
//   'Type3Id',
// ]
//&$filter=Type1Id eq 'TECHNI'
