import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

export default function Checklist({ name, value, onChange, label, ...rest }) {
  const handleChange = e =>
    onChange({ target: { name, value: e.target.checked } })

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox {...rest} checked={!!value} onChange={handleChange} />
        }
        label={label}
      />
    </div>
  )
}
