import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { Link, useParams, useHistory } from 'react-router-dom'
import fetcher from 'utils/api'
import useSWR from 'swr'
import Layout from 'components/Layout'
import * as utils from 'utils/utils'
import ListView from 'components/ListView'
import * as api from 'utils/api'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import DropDown from 'fields/DropDown'
import AutoComplete from 'fields/AutoComplete'
import Error from 'components/Error'

import WarningIcon from '@material-ui/icons/Warning'
import Field from 'fields/Field'
import {
  useFieldState,
  useFieldDispatch,
  FieldProvider,
} from 'fields/FormFieldProvider'
import { LinearProgress } from '@material-ui/core'

export function DivisionEquipmentAdd({ onAfterSubmit, item }) {
  const { inventoryId, divisionId } = useParams()
  const history = useHistory()

  const state = useFieldState()
  const dispatch = useFieldDispatch()

  const isNew = inventoryId === 'new'

  const initPayload = {
    StatusCode: '',
    TypeId: '',
    ProductItemId: '',
    SerialNumber: '',
    DivisionId: divisionId,
    OperatingCompanyCode: 'A',
  }

  const { data, error, isValidating, mutate } = useSWR(
    () =>
      inventoryId && !isNew
        ? `Inventories(${inventoryId})?$select=StatusCode,TypeId,ProductItemId,SerialNumber,DivisionId,OperatingCompanyCode,Description&$expand=Product($select=Description,ProductItemId)`
        : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const cats = useSWR(() => `ProductCategories`, fetcher)

  const updated = React.useRef(false)
  React.useEffect(() => {
    if (updated.current === false) {
      if (isNew) {
        dispatch({ data: initPayload })
        updated.current = true
      }
      if (data) {
        dispatch({ data: data[0] })
        updated.current = true
      }
    }
  }, [data])

  const handleSaveClick = async () => {
    const response = isNew
      ? await api.call(`Inventories`, state, 'POST')
      : await api.call(`Inventories(${inventoryId})`, state, 'PATCH')
    mutate(oldState => ({ ...oldState, ...state }))
    typeof onAfterSubmit === 'function' && onAfterSubmit()
    history.goBack()
  }

  const handleDeleteClick = async () => {
    if (isNew) return
    const newState = { ...state, StatusCode: 'WRIOFF' }

    await api.call(`Inventories(${inventoryId})`, newState, 'PATCH')
    mutate(oldState => ({ ...oldState, ...newState }))
    typeof onAfterSubmit === 'function' && onAfterSubmit()
    history.goBack()
  }

  if (error) return <Error error={error} />
  //`ProductItemSearch?$expand=ProductItem($expand=Category($select=Description,OperatingCompanyCode,CategoryId);$filter=Tag/Obsolete eq false);$select=ProductItemId,Sku)&$select=ProductItem&$filter=ProductItem/Obsolete eq 0&$orderby=Score desc&$top=25&$count=true`
  //  or CategoryId eq 'd0448acd-d75a-4776-92b7-97b12e5b8e9d'
  return (
    <Dialog
      onClose={history.goBack}
      aria-labelledby="customized-dialog-title"
      open
    >
      <DialogTitle onClose={history.goBack}>
        Edit division equipment list
      </DialogTitle>
      <DialogContent>
        {isValidating && !data && <LinearProgress />}
        {inventoryId === 'new' ? (
          <Field
            autocomplete
            label="Search for product"
            // queryString={search =>
            //   `ProductItems?
            //       $filter=contains(Description,'${search}') and CategoryId eq '3ac6526a-fb5b-4f65-89bb-88eeb54159f2'&
            //       $expand=Category&
            //       $select=Description,ProductItemId&
            //       $top=20`
            // }
            queryString={search =>
              `ProductItems?
                  $filter=${utils.searchContains(
                    search
                  )} and CategoryId eq '3ac6526a-fb5b-4f65-89bb-88eeb54159f2'&
                  $expand=Category&
                  $select=Description,ProductItemId&
                  $top=20`
            }
            // $select=Description,ProductItemId&
            labels="Description"
            values="ProductItemId"
            name="ProductItemId"
          />
        ) : (
          <>
            <Typography variant="h3">
              {state?.Product?.Description || state.Description}
            </Typography>
            {state.Product === null && (
              <Typography variant="caption">
                <WarningIcon /> This product isn't tied to ProspectSoft
              </Typography>
            )}
          </>
        )}
        <Field name="Description" label="Description" textarea />
        <Field
          dropdown
          queryString={`InventoryTypes?$select=Code,Description&$filter=Obsolete eq 0&$top=50&$count=true`}
          labels="Description"
          values="Code"
          label="Inventory type"
          name="TypeId"
        />
        <Field
          dropdown
          queryString={`InventoryStatus?$select=Description,StatusCode&$filter=Obsolete eq 0 or StatusCode eq 'ACTIVE'&$top=50&$count=true`}
          labels="Description"
          values="StatusCode"
          label="Inventory status"
          name="StatusCode"
        />
        <Field text name="SerialNumber" label="Serial number" />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'space-between' }}>
        {!isNew && (
          <Button style={{ background: 'darkred' }} onClick={handleDeleteClick}>
            Delete
          </Button>
        )}
        <Button onClick={handleSaveClick}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default props => (
  <FieldProvider>
    <DivisionEquipmentAdd {...props} />
  </FieldProvider>
)
