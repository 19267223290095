import React from 'react'

const FieldStateContext = React.createContext()
const FieldDispatchContext = React.createContext()

function FieldReducer(state, { name, value, data }) {
  if (data) return { ...data, ...state } // so as not to overwrite
  // state[name] = value
  // return state
  return { ...state, [name]: value }
}

function FieldProvider({ children, initState = {} }) {
  const [state, dispatch] = React.useReducer(FieldReducer, initState)

  return (
    <FieldStateContext.Provider value={state}>
      <FieldDispatchContext.Provider value={dispatch}>
        {children}
      </FieldDispatchContext.Provider>
    </FieldStateContext.Provider>
  )
}
function useFieldState() {
  const context = React.useContext(FieldStateContext)
  // if (context === undefined) {
  //   throw new Error('useGlobalState must be used within a GlobalProvider')
  // }
  return context
}
function useFieldDispatch() {
  const context = React.useContext(FieldDispatchContext)
  // if (context === undefined) {
  //   throw new Error('useGlobalDispatch must be used within a GlobalProvider')
  // }
  return context
}
export { FieldProvider, useFieldState, useFieldDispatch }
