import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import AutoComplete from 'fields/AutoComplete'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import createPersistedState from 'use-persisted-state'
import * as utils from 'utils/utils'

const useRecentState = createPersistedState('recent')

export default function Companies() {
  // const [search, setSearch] = React.useState('')
  const [recent, setRecent] = useRecentState([])
  console.log(recent)
  // const recentQuery = useSWR(
  //   () =>
  //     `MyRecentObjects?$filter=ObjectType eq 'Division'&$orderby=LastAccessed%20desc&$top=15
  //         `
  //       .replace(/\n/g, '')
  //       .replace(/  +/g, ''),
  //   fetcher
  // )

  // console.log(recentQuery.data)

  const history = useHistory()

  const handleChange = (e, option) => {
    const {
      target: { value, name },
    } = e

    if (!value) return

    setRecent(
      [
        // query.data.find(division => division.DivisionId === value),
        option,
        ...recent.filter(recent => recent.DivisionId !== option.DivisionId),
      ].slice(0, 5)
    )

    history.push(`/division/${value}/info`)
  }

  return (
    <>
      <Typography variant="h3" gutterBottom>
        Search for a division
      </Typography>
      <AutoComplete
        placeholder="Search for a division"
        queryString={search => `Divisions?
        $filter=${utils.searchContains(search, 'Name')}&
        $expand=Address($select=AddressLine1,Postcode)&
        $select=Name,DivisionId&
        $top=10
      `}
        values="DivisionId"
        labels="Name"
        onChange={handleChange}
        helperText="Enter three or more characters to search"
      />

      {recent.length > 0 && (
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Recent searches
          </Typography>
          <Paper>
            <List>
              {recent.map(division => (
                <Link
                  key={division.DivisionId}
                  to={`/division/${division.DivisionId}/info`}
                  // component="div"
                >
                  <ListItem>
                    <ListItemText primary={division.Name} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="delete">
                        <ArrowForwardIos />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Paper>
        </Box>
      )}
    </>
  )
}
