export const fromSystem = (n, d = 4) => n / Math.pow(10, d)

export const toSystem = (n, d = 4) => n * Math.pow(10, d)

export const toInt = (n, d) => Math.Floor(fromSystem(n, d))

export const toPounds = (n, d = 4) =>
  n == 0 ? 'Free' : `£${fromSystem(n, d).toFixed(2)}`

export const sanitise = o =>
  Object.keys(o).reduce(
    (prev, key) =>
      typeof o[key] === 'string' ||
      typeof o[key] === 'number' ||
      typeof o[key] === 'boolean'
        ? { ...prev, [key]: o[key] }
        : prev,
    {}
  )

export const date = time => {
  const date = new Date()
  const iso = date.toISOString().split('T')[0]
  return iso
}

export const makeHandleChange = set => ({ target }) =>
  set(state => ({ ...state, [target.name]: target.value }))

export const searchContains = (search, key = 'Description') =>
  search
    .trim()
    .replace(/  +/g, '')
    .split(' ')
    .map((word, i) => `${i > 0 ? ' and ' : ''}contains(${key},'${word}')`)
    .join('')

export const paramEquals = (array, param, operator = 'eq') =>
  array.reduce(
    (prev, code, i, a) =>
      `${prev}${i > 0 ? ' or ' : ''}${param} ${operator} '${code}'`,
    ''
  )
