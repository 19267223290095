import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import useSWR from 'swr'
import fetcher from 'utils/api'

export default function Asynchronous({
  placeholder = '',
  label,
  labels = 'Description',
  values = 'Code',
  value,
  queryString,
  onChange,
  margin,
  helperText,
  ...rest
}) {
  const [search = '', setSearch] = React.useState('')

  const query = useSWR(
    () =>
      search.length > 2
        ? queryString(search).replace(/\n/g, '').replace(/  +/g, '')
        : null,
    fetcher
  )

  const { data, error, isValidating } = query

  const handleInputChange = e => setSearch(e?.target?.value || '')

  const ref = React.useRef()

  const handleChange = (e, value, r) =>
    onChange(
      {
        target: {
          name: ref.current.getAttribute('name'),
          value: value[values],
        },
      },
      value
    )

  const objValue = data?.find(option => option[values] === value)

  return (
    <Autocomplete
      ref={ref}
      getOptionSelected={(option, value) => option[values] === objValue[values]}
      getOptionLabel={option => option[labels]}
      options={data || []}
      loading={isValidating}
      onInputChange={handleInputChange}
      noOptionsText={search.length < 3 ? 'Type to search' : 'No results'}
      onChange={handleChange}
      freeSolo
      value={objValue}
      {...rest}
      renderInput={params => (
        <TextField
          {...params}
          margin={margin}
          label={placeholder || label}
          // value={search}
          variant="outlined"
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isValidating ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

// export const CompaniesAutoComplete = () => {
//   return (
//     <Autocomplete
//       queryString={search => `Divisions?
//         $filter=contains(Name,'${search}')&
//         $expand=Address($select=AddressLine1,Postcode)&
//         $top=10`}
//       labels="Description"
//       values="Code"
//     />
//   )
// }
