import { Box, LinearProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'
import createPersistedState from 'use-persisted-state'
import fetcher, * as api from 'utils/api'
import { ClientSignoff } from './FinaliseSignoff'
import FinaliseEmails from 'views/FinaliseEmails'
import * as utils from 'utils/utils'
import emailTemplate from 'utils/emailTemplate'
import EmailSection from 'components/EmailSection'

const useChecklistsState = createPersistedState('checklists')
const useSignoffsState = createPersistedState('signoffs')

const useStyles = makeStyles(theme => ({
  root: {},
}))

const options = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

export default function CompanyInfo() {
  const { divisionId, problemId } = useParams()
  const history = useHistory()

  // ADD DIVISION NAME
  const queryDivisionInfo = useSWR(
    () =>
      divisionId
        ? `Divisions(DivisionId=${divisionId})?$select=Name,Company,Address&$expand=Company($select=Name),Address($select=AddressLine1,AddressLine2,Postcode)`
        : null,
    fetcher,
    options
  )

  const queryDivisionEquipment = useSWR(
    () =>
      divisionId
        ? `Inventories?$expand=Status($select=Description)&$select=Description,SerialNumber,Product,Status&$filter=DivisionId eq ${divisionId} and StatusCode ne 'WRIOFF'&$orderby=InventoryId desc&$top=10&$skip=0&$count=true`
        : null,
    fetcher,
    options
  )

  const queryProblemInfo = useSWR(
    () =>
      problemId
        ? `Problems(${problemId})?
            $select=ProblemId,Description,SituationSummary,LastUpdatedTimestamp&
            $expand=Type3($select=Description),LastUpdatedByUser($select=UserName)`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher,
    options
  )

  const queryProblemQuote = useSWR(
    () =>
      problemId
        ? `Quotes?
            $filter=ProblemId eq ${problemId}&$select=Description`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher,
    options
  )
  const htmlRef = React.useRef()

  const [checklists, setChecklists] = useChecklistsState([])
  console.log(checklists)
  const { problemId: _, ...checklist } = checklists.find(
    item => item?.problemId == problemId
  )
  const [signoffs, setSignoffs] = useSignoffsState([])
  const initSignoff = signoffs.find(item => item?.problemId == problemId) || {}

  const [signoff, setSignoff] = React.useState({
    ...initSignoff,
    Date: utils.date(),
  })

  const handleSignoffChange = e => {
    const { target } = e
    setSignoff(state => ({ ...state, [target.name]: target.value }))
  }

  const handleFinaliseClick = async () => {
    const html = emailTemplate(htmlRef.current.innerHTML)
    // const data = new Blob([html], { type: 'data:attachment/text,' })
    // const url = window.URL.createObjectURL(data)

    setSignoffs(signoffs => [
      ...signoffs.filter(
        signoff => !!signoff && signoff.problemId !== problemId
      ),
      signoff,
    ])

    const date = new Date()
    const iso = date.toISOString().split('T')[0]

    const messages = []
    try {
      // try {
      //   await api.call(
      //     `Problems(ProblemId=${problemId})`,
      //     { StatusId: signoff.StatusId },
      //     'PATCH'
      //   )
      //   messages.push(`- Status updated to ${signoff.StatusId}`)
      // } catch (e) {
      //   messages.push(`! Error updating problem status to ${signoff.StatusId}`)
      //   console.log(e)
      //   if (
      //     !window.confirm(
      //       `Problem status could not be set to ${signoff.StatusId}, do you wish to continue uploading the problem service document?`
      //     )
      //   )
      //     throw null
      // }

      try {
        const document = await api.call(`Documents`, {
          ProblemId: problemId,
          // InventoryId: null,
          DivisionId: divisionId,
          DocumentTypeCode: 'EXTERN',
          Description: `Visit summary ${iso}`,
        })
        messages.push(`- Created document metadata for ${document.DocumentId}`)

        await api.call(`Documents(${document.DocumentId})`, html, 'POST', {
          'content-type': 'application/octet-stream',
          'content-length': html.length,
          'X-Replace': true,
          'x-filename': `Visit summary - ${problemId} - ${iso}`,
          'x-locale': 'en-GB,',
        })
        messages.push(
          `- Uploaded document content data for ${document.DocumentId}`
        )
      } catch (e) {
        messages.push(`! Error uploading document ${document.DocumentId}`)
      }

      console.log(messages.join('\n'))
      history.push(`/division/${divisionId}/complete/${problemId}`)
    } catch (e) {
      if (e !== null) alert('An error has occured', e)
    }
  }

  const handleEmailClick = async () => {
    const html = htmlRef.current.innerHTML
    const body = emailTemplate(html)
    const to = emails.filter(email => email)
    try {
      const data = {
        body,
        to,
        subject: 'Volcano site visit ' + utils.date(),
      }
      const res = await api.email(data)
      alert('Email sent to ' + to.join(', '))
    } catch (e) {
      alert(e)
    }

    // click a link with the dl url
  }

  const [signatureImage, setSignatureImage] = React.useState()

  React.useEffect(() => {
    if (queryProblemInfo.data)
      setSignoff(state => ({
        ...state,
        StatusId: queryProblemInfo?.data?.[0]?.StatusId || '',
      }))
  }, [queryProblemInfo.data])

  const [emails, setEmails] = React.useState([''])

  console.log('CHECKLISTTTTTTTTTTTTTTTT', checklists, checklist)
  return (
    <>
      <div id="pdf" ref={htmlRef}>
        <EmailSection
          title="Client/Site information"
          query={queryDivisionInfo}
        />

        <EmailSection
          title="Equipment on site"
          query={queryDivisionEquipment}
        />

        <EmailSection title="Visit" query={queryProblemInfo} />

        <EmailSection
          title="Invoice reference number"
          query={queryProblemQuote}
        />

        {/* <EmailSection title="Equipment checklist" data={checklist} /> */}
        <div className="hide-in-app">
          <EmailSection title="Client signoff" data={signoff}>
            {signatureImage && <img src={signatureImage} />}
          </EmailSection>
        </div>
      </div>

      <Paper component={Box} p={2} my={2}>
        <Typography variant="h4">Client signoff</Typography>
        <ClientSignoff
          onChange={handleSignoffChange}
          state={signoff}
          onCanvasChange={setSignatureImage}
        />
      </Paper>

      <Paper component={Box} p={2} my={2}>
        <Typography variant="h4" gutterBottom>
          Email
        </Typography>
        <Typography gutterBottom>
          Send an email confirmation to the selected selected recipients
        </Typography>
        <FinaliseEmails state={emails} setState={setEmails} />
        <Button
          // disabled={!clientSignoff['StatusId']}
          onClick={handleEmailClick}
          fullWidth
        >
          Send email
        </Button>
      </Paper>

      <Paper component={Box} p={2}>
        <Typography variant="h4" gutterBottom>
          Complete
        </Typography>
        <Typography>
          This will save the service report as a document in ProspectSoft,
          remember to complete and process in ProspectSoft. This will not send
          confirmation emails
        </Typography>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button
            disabled={!signoff['StatusId']}
            onClick={handleFinaliseClick}
            fullWidth
          >
            Save report to ProspectSoft
          </Button>
        </Box>
      </Paper>
    </>
  )
}
