import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from 'components/Error'
import Field from 'fields/Field'
import {
  FieldProvider,
  useFieldDispatch,
  useFieldState,
} from 'fields/FormFieldProvider'
import { productItemsSearchQuerystring } from 'fields/ProductItemsDropdown'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'

export function QuoteLineAdd({ onAfterSubmit, quoteId }) {
  const history = useHistory()
  const { divisionId, problemId, lineId } = useParams()

  const state = useFieldState()
  const dispatch = useFieldDispatch()

  const isNew = lineId === 'new'

  const { data, error, isValidating, mutate } = useSWR(
    () => (lineId && !isNew ? `QuoteLines(${lineId})` : null),
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const updated = React.useRef(false)
  React.useEffect(() => {
    if (data)
      if (updated.current === false) {
        updated.current = true
        dispatch({ data: data[0] })
      }
  }, [data])

  const handleSaveClick = async () => {
    const payload = {
      QuoteId: quoteId,
      OperatingCompanyCode: 'A',
      // QuantityDecimals: 4,
      ...(isNew ? {} : { QuoteId: quoteId }),
      ...state,
      // Quantity: 1,
    }

    const response = isNew
      ? await api.call(`QuoteLines`, payload)
      : await api.call(`QuoteLines(${lineId})`, payload, 'PATCH')

    typeof onAfterSubmit === 'function' && onAfterSubmit()
    history.goBack()
  }

  const handleDeleteClick = async () => {
    if (!isNew) {
      await api.call(`QuoteLines(LineId=${lineId})`, null, 'DELETE')
      typeof onAfterSubmit === 'function' && onAfterSubmit()
    }
    history.goBack()
  }

  if (error) return <Error error={error} />

  return (
    <Dialog
      onClose={history.goBack}
      aria-labelledby="customized-dialog-title"
      open
    >
      {isValidating && <LinearProgress />}
      <DialogTitle onClose={history.goBack}>Edit quote line</DialogTitle>
      <DialogContent>
        <Field
          autocomplete
          name="ProductItemId"
          label="Product"
          values="ProductItemId"
          labels="Description"
          queryString={productItemsSearchQuerystring}
          helperText="type 2 search"
        />
        <Field quantity label="Quantity" name="DecimalQuantity" />
        <Field textarea name="Description" label="Description" />
      </DialogContent>
      <DialogActions>
        {!isNew && <Button onClick={handleDeleteClick}>Delete</Button>}
        <Button onClick={handleSaveClick}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}

export default props => (
  <FieldProvider>
    <QuoteLineAdd {...props} />
  </FieldProvider>
)
