import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Checkbox from 'fields/Checkbox'
import React from 'react'
import DropDown from 'fields/DropDown'
import useSWR from 'swr'
import { useParams } from 'react-router-dom'
import fetcher from 'utils/api'

export default function ClientSignoff({ setState, state }) {
  const { divisionId } = useParams()

  const { data, error, isValidating } = useSWR(
    () =>
      divisionId
        ? `Contacts?$filter=DivisionId eq ${divisionId}&$select=Email,Forename,JobTitle,MobilePhoneNumber,PhoneNumber,PreferredName,Surname,Title,ContactId&$top=20`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  const handleTextChange = e => {
    e.persist()
    setState(state => [e.target.value, ...state.slice(1)])
  }

  const handleCheckboxChange = e => {
    setState(state => {
      const newState = state.filter(email => email !== e.target.name)
      if (e.target.value) newState.push(e.target.name)
      return newState
    })
  }

  return (
    <>
      <div>
        {Array.isArray(data) &&
          data.map((contact, i) => (
            <Checkbox
              key={i}
              name={contact.Email}
              label={`${contact.PreferredName || contact.Forename} ${
                contact.Surname || ''
              } (${contact.Email})`}
              onChange={handleCheckboxChange}
              value={state.includes(contact.Email)}
              margin="normal"
            />
          ))}
        <TextField
          type="email"
          label="Enter email manually"
          name="extra email"
          onChange={handleTextChange}
          value={state[0] || ''}
          margin="normal"
          fullWidth
        />
      </div>
    </>
  )
}
