import { Container } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Step from '@material-ui/core/Step'
import StepButton from '@material-ui/core/StepButton'
import Stepper from '@material-ui/core/Stepper'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import DivisionName from 'components/DivisionName'
import Layout from 'components/Layout'
import 'global.css'
import React from 'react'
import { Link, Route, Switch, useHistory } from 'react-router-dom'
import Companies from 'views/Division'
import DivisionEquipment from 'views/DivisionEquipment'
import DivisionInfo from 'views/DivisionInfo'
import DivisionProblems from 'views/DivisionProblems'
import Login from 'views/Login'
import ProblemChecklist from 'views/ProblemChecklist'
import ProblemComplete from 'views/ProblemComplete'
import ProblemFinalise from 'views/ProblemFinalise'
import ProblemInfo from 'views/ProblemInfo'
import ProblemQuote from 'views/ProblemQuote'

const visitSteps = [
  { name: 'info', label: 'Info', Component: ProblemInfo },
  { name: 'equipment', label: 'Kit', Component: DivisionEquipment },
  { name: 'quote', label: 'Quote', Component: ProblemQuote },
  { name: 'checklist', label: 'Checklist', Component: ProblemChecklist },
  { name: 'finalise', label: 'Finalise', Component: ProblemFinalise },
  // { name: 'finalise', label: 'Finalise', Component: ProblemFinalise },
]

const getTabValue = url =>
  window.location.pathname.replace(`${url}/`, '').split('/')[0]

export default function App() {
  const pat = localStorage.getItem('pat')
  const history = useHistory()
  if (!pat) history.push('/login')

  // React.useEffect(() => {
  //   if (!pat) router.push('/login')
  // }, [pat])
  // if (!pat)
  //   return (
  //     <Layout>
  //       <Login />
  //     </Layout>
  //   )
  return (
    <Switch>
      <Route path="/login">
        <Layout>
          <Login />
        </Layout>
      </Route>
      <Route path="/" exact>
        <Layout>
          <Companies />{' '}
        </Layout>
      </Route>
      <Route
        path="/division/:divisionId/problem/:problemId"
        render={({
          match: {
            path,
            url,
            params: { divisionId, problemId },
          },
        }) => (
          <Layout header="Site visit">
            {/* <Paper> */}
            <Container disableGutters>
              <DivisionName />
              <Stepper
                alternativeLabel
                nonLinear
                activeStep={visitSteps.findIndex(
                  ({ name }) => `${url}/${name}` === window.location.pathname
                )}
              >
                {visitSteps.map(({ name, label, Component }) => (
                  <Step key={`${url}/${name}`}>
                    <StepButton component={Link} to={`${url}/${name}`}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Container>
            <Switch>
              {visitSteps.map(({ name, Component }, i, a) => (
                <Route key={name} path={`${path}/${name}`}>
                  <Component nextPath={`${url}/${a[i + 1]?.name}`} />
                </Route>
              ))}
            </Switch>
          </Layout>
        )}
      />
      <Route
        path="/division/:divisionId/complete/:problemId"
        render={({
          match: {
            path,
            url,
            params: { divisionId, problemId },
          },
        }) => (
          <Layout>
            <ProblemComplete />
          </Layout>
        )}
      />
      <Route
        path="/division/:divisionId"
        render={({
          match: {
            path,
            url,
            params: { divisionId, problemId },
          },
        }) => (
          <Layout>
            <DivisionName />
            <Paper>
              <Tabs
                value={
                  window.location.pathname.replace(`${url}/`, '').split('/')[0]
                }
                variant="fullWidth"
                indicatorColor="primary"
              >
                <Tab
                  label="Info"
                  component={Link}
                  to={`${url}/info`}
                  value={`info`}
                />
                <Tab
                  label="Equipment"
                  component={Link}
                  to={`${url}/equipment`}
                  value={`equipment`}
                />
                <Tab
                  label="Problems"
                  component={Link}
                  to={`${url}/problems`}
                  value={`problems`}
                />
              </Tabs>
            </Paper>
            <Switch>
              <Route path={`${path}/info`} component={DivisionInfo} />
              <Route path={`${path}/equipment`} component={DivisionEquipment} />
              <Route path={`${path}/problems`} component={DivisionProblems} />
            </Switch>
          </Layout>
        )}
      />
      <Route
        path="/finalise/:divisionId/:problemId"
        render={({
          match: {
            path,
            url,
            params: { divisionId },
          },
        }) => (
          <Layout>
            <ProblemFinalise />
          </Layout>
        )}
      />
      <Route>
        <Layout>
          <p>bad url :(</p>
        </Layout>
      </Route>
    </Switch>
  )
}
