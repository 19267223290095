import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Add, Remove } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import * as utils from 'utils/utils'

const useStyles = makeStyles(theme => ({
  root: { display: 'flex', alignItems: 'center' },
  textfield: { flexGrow: 1, flexShrink: 1 },
  icon: { width: 60, height: 60, marginLeft: theme.spacing(2) },
}))

export default function Quantity({
  value: propsValue,
  name,
  min = 0,
  max = Infinity,
  onChange = () => {},
  // decimals = 4,
  ...rest
}) {
  const classes = useStyles()

  const handleIncrement = e =>
    onChange({
      target: {
        name,
        value: Math.min(parseInt(value) + 1, max),
      },
    })
  const handleDecrement = e =>
    onChange({
      target: {
        name,
        value: Math.max(parseInt(value) - 1, min),
      },
    })

  const value = isNaN(parseInt(propsValue)) ? 1 : parseInt(propsValue)

  // console.log(propsValue)
  // const value = utils.fromSystem(rawValue, decimals) //TODO: quantity multiplier
  return (
    <div className={classes.root}>
      <TextField
        className={classes.textfield}
        type="number"
        inputProps={{
          min,
          max,
        }}
        onChange={onChange}
        name={name}
        value={value || 1}
        {...rest}
      ></TextField>
      <IconButton
        name="add"
        onClick={handleDecrement}
        className={classes.icon}
        disabled={value <= min}
      >
        <Remove />
      </IconButton>
      <IconButton
        name="remove"
        onClick={handleIncrement}
        className={classes.icon}
        disabled={value >= max}
      >
        <Add />
      </IconButton>
    </div>
  )
}
