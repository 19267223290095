import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Header from 'components/Header'
import React from 'react'
import Breadcrumbs from './Breadcrumbs'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(2),
  },
  bottomNavigation: {
    paddingBottom: 60,
  },
}))

export default function Layout({
  header,
  children,
  breadcrumbs,
  // bottomNavigation,
}) {
  const classes = useStyles()
  return (
    <>
      <Header />
      {breadcrumbs && <Breadcrumbs />}
      <Container
        className={clsx(
          classes.root,
          // bottomNavigation &&
          classes.bottomNavigation
        )}
      >
        {/* <Typography variant="h3">{header}</Typography> */}
        {children}
      </Container>
    </>
  )
}
