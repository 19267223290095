import AppBar from '@material-ui/core/AppBar'
import { makeStyles } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import React from 'react'
import { Link } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useHistory } from 'react-router-dom'

import MenuIcon from '@material-ui/icons/Menu'

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    justifyContent: 'center',
  },
  logo: {
    marginTop: 2,
    width: 146,
    height: 48,
  },
  menu: {
    color: 'inherit',
    position: 'absolute',
    right: 16,
    top: 4,
  },
}))

export default function Header({ children }) {
  const classes = useStyles()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    localStorage.removeItem('pat')
    history.push('/login')
  }

  const pat = localStorage.getItem('pat')
  return (
    <AppBar position="static">
      <Toolbar className={classes.root}>
        <Link to="/">
          <img src="/logo.jpg" className={classes.logo} />
        </Link>
        {pat && (
          <div>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.menu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Link to="/" component={MenuItem}>
                Home
              </Link>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}
