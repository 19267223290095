import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import AutoComplete from 'fields/AutoComplete'
import Checkbox from 'fields/Checkbox'
import DropDown from 'fields/DropDown'
import { useFieldDispatch, useFieldState } from 'fields/FormFieldProvider'
import Quantity from 'fields/Quantity'
import Box from '@material-ui/core/Box'

export default function ContextualField({
  state: propsState,
  dispatch: propsDispatch,
  name,
  margin = 'normal',
  fullWidth = true,
  textarea,
  dropdown,
  autocomplete,
  quantity,
  checkbox,
  heading,
  select,
  number,
  ...rest
}) {
  const state = useFieldState()
  const dispatch = useFieldDispatch()

  const handleChange = e => {
    const {
      target: { value },
    } = e
    dispatch({ name, value })
  }

  const value = state?.[name] || ''

  const props = {
    name,
    value,
    margin,
    fullWidth,
    onChange: handleChange,
    ...rest,
  }

  if (textarea) return <TextField multiline {...props} />

  if (dropdown) return <DropDown {...props} />

  if (autocomplete) return <AutoComplete {...props} />

  if (quantity) return <Quantity {...props} />

  if (checkbox) return <Checkbox {...props} margin={null} fullWidth={false} />

  if (heading)
    return (
      <Box my={2}>
        <Typography variant="h4">{props.label}</Typography>
      </Box>
    )

  if (select) {
    const { options, ...selectProps } = props
    return (
      <TextField {...selectProps} select>
        {options.map(({ value, title }) => (
          <MenuItem key={value} value={value}>
            {title}
          </MenuItem>
        ))}
      </TextField>
    )
  }

  if (number) return <Quantity {...props} decimals={0} />
  return <TextField {...props} />
}

// export default ({ state, dispatch, name, ...rest }) =>
//   React.useMemo(
//     () => <ContextualField {...rest} {...{ state, dispatch, name }} />,
//     [state[name], dispatch, name]
//   )
