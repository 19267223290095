import React from 'react'
import fetcher from 'utils/api'
import useSWR from 'swr'
import { Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { useParams } from 'react-router-dom'

export default function CompanyName() {
  const { divisionId } = useParams()

  const { data, error, isValidating } = useSWR(
    () =>
      divisionId ? `Divisions(DivisionId=${divisionId})?$select=Name` : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (error) return <Typography />

  return (
    <Typography variant="h4" align="center" gutterBottom>
      {!data ? <Skeleton variant="text" /> : data[0].Name}
    </Typography>
  )
}
