import { IconButton, Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import { Clear as ClearIcon } from '@material-ui/icons'
import Checkbox from 'fields/Checkbox'
import DropDown from 'fields/DropDown'
import React from 'react'
import CanvasDraw from 'react-canvas-draw'

const blankSaveData = '{"lines":[],"width":400,"height":200}'

export function ClientSignoff({ onChange, state, onCanvasChange }) {
  const container = React.useRef()
  const ref = React.useRef()
  const [dimensions, setDimensions] = React.useState({
    width: 400,
    height: 200,
  })
  React.useLayoutEffect(() => {
    setDimensions({
      width: container.current.clientWidth,
      height: container.current.clientWidth * 0.5,
    })
  }, [container])

  // React.useLayoutEffect(() => {
  //   console.log('SAVEDFATA', ref.current.getSaveData())
  // }, [ref])

  const handleCanvasChange = e => {
    const image = ref.current.canvas.drawing.toDataURL('image/png')
    onCanvasChange(image)
    onChange({ target: { name: 'HIDDEN-signature', value: e.getSaveData() } })
  }

  const handleCanvasClear = () => {
    ref.current.clear()
    onChange({ target: { name: 'HIDDEN-signature', value: null } })
    onCanvasChange()
  }

  // const query = useSWR(`/ProblemStatusSequences`, fetcher)
  // console.log(query.data)
  return (
    <>
      <div>
        <DropDown
          onChange={onChange}
          dropdown
          name="StatusId"
          label="Final status"
          values="Description"
          labels="Description"
          //prettier-ignore
          queryString={`ProblemStatus?$select=StatusCode,Description&$filter=
          DeadFlag eq 1 and 
          StatusCode ne 'RETREJ' and 
          StatusCode ne 'RETNOI' and 
          StatusCode ne 'CLOSED' and 
          StatusCode ne 'CLONRT'
          `.replace(/\n/g, '').replace(/  +/g, ' ')}
          fullWidth
          margin="normal"
          value={state['StatusId'] || ''}
        />
      </div>
      <div>
        <TextField
          name="Client name"
          label="Client name"
          onChange={onChange}
          value={state['Client name'] || ''}
          fullWidth
          margin="normal"
          disabled={state['Signed']}
        />
      </div>
      <div>
        <Checkbox
          name="Signed"
          label={`Signed by ${state['Client name'] || '...'}`}
          onChange={onChange}
          value={!!state['Signed']}
          margin="normal"
          disabled={!state['Client name']}
        />
      </div>
      <div ref={container}>
        <CanvasDraw
          brushRadius={2}
          lazyRadius={3}
          canvasWidth={dimensions.width}
          canvasHeight={dimensions.height}
          ref={ref}
          onChange={handleCanvasChange}
          saveData={state['HIDDEN-signature'] || blankSaveData}
          immediateLoading={true}
        />
        <Box align="right" pt={2}>
          <IconButton onClick={handleCanvasClear}>
            <ClearIcon />
          </IconButton>
        </Box>
      </div>

      <div>
        <Typography>Date: {state['Date']}</Typography>
      </div>
    </>
  )
}
