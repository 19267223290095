import { LinearProgress } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import PersonIcon from '@material-ui/icons/Person'
import Skeleton from '@material-ui/lab/Skeleton'
import Error from 'components/Error'
import React from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import fetcher from 'utils/api'

export default function CompanyInfo() {
  const { divisionId } = useParams()

  const { data, error, isValidating } = useSWR(
    () =>
      divisionId
        ? `Divisions(DivisionId=${divisionId})?
            $expand=
              Company($select=CompanyId,Name),
              Address($select=AddressId,AddressLine1,AddressLine2,Postcode),
              Contacts($select=Email,Forename,JobTitle,MobilePhoneNumber,PhoneNumber,PreferredName,Surname,Title,ContactId)`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  if (error) return <Error error={error} />
  const item = data ? data[0] : {}

  const Display = ({ children, ...rest }) => (
    <Typography {...rest}>
      {isValidating && !data ? <Skeleton type="text" /> : children}
    </Typography>
  )
  return (
    <>
      <Typography variant="h3" gutterBottom>
        Division info
      </Typography>
      <Box component={Paper} p={2} mb={2}>
        <Display variant="h3" gutterBottom>
          {item?.Name}
        </Display>
        <Display>{item?.Address?.AddressLine1}</Display>
        <Display>{item?.Address?.AddressLine2}</Display>
        <Display>{item?.Address?.Postcode}</Display>
      </Box>
      <Typography variant="h3" gutterBottom>
        Contacts
      </Typography>

      <List component={Paper}>
        {isValidating && <LinearProgress />}
        {!isValidating && item.Contacts?.length < 1 ? (
          <Box px={2}>
            <Typography>No contacts</Typography>
          </Box>
        ) : (
          item?.Contacts?.map(contact => (
            <ListItem key={contact.ContactId}>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Display component="span">
                    {contact.PreferredName || contact.Forename}{' '}
                    {contact.Surname}
                  </Display>
                }
                secondary={
                  <Display component="span">
                    {contact.Email || 'No email'}
                  </Display>
                }
              />
            </ListItem>
          ))
        )}
      </List>
      {/* {item?.Contacts?.map(contact => (
        <Box component={Paper} p={2}>
          <Display>
            {contact.PreferredName || contact.Forename} {contact.Surname}
          </Display>
          <Display>{contact.Email || 'No email'}</Display>
        </Box>
      ))} */}
      {/* 
      {item?.Contacts?.map(contact => (
        <Box component={Paper} p={2}>
          <Display>
            {contact.PreferredName || contact.Forename} {contact.Surname}
          </Display>
          <Display>{contact.Email || 'No email'}</Display>
        </Box>
      ))} */}
    </>
  )
}
