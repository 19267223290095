import React from 'react'
import LinearProgress from '@material-ui/core/LinearProgress'

export default function Section({ query, title, data: propsData, children }) {
  const [hidden, setHidden] = React.useState(true)

  const { data: queryData, isValidating } = query || {}
  const data = propsData ? [propsData] : queryData
  if (!Array.isArray(data)) return null

  const Label = ({ children, bool }) => (
    <span
      style={{
        minWidth: bool ? '50%' : '100%',
        display: 'inline-block',
        marginTop: 10,
      }}
    >
      {typeof children === 'string'
        ? children.replace(/([A-Z]+)/g, ' $1').trim()
        : children}
      :
    </span>
  )
  const Row = ({ children }) => <div>{children}</div>

  const Value = ({ children }) => <b>{children}</b>

  const Display = ({ o }) => {
    if (o === undefined || o === null) return null
    if (typeof o === 'string' || typeof o === 'number')
      return <Value>{o}</Value>
    if (typeof o === 'boolean') return <Value>{o ? 'Yes' : 'No'}</Value>

    if (Array.isArray(o))
      return o.map((o, i, a) => (
        <div key={i}>
          {i === 0 && <hr />}
          <Label>{i}</Label>
          <Display o={o} />
          <hr />
        </div>
      ))

    const keys = Object.keys(o)
    return keys.map((key, i) => {
      if (key.startsWith('HIDDEN')) return <span />
      if (typeof o[key] === 'undefined' || o[key] === null)
        return (
          <Row key={i}>
            <Label>{key}</Label> -
          </Row>
        )

      if (typeof o[key] === 'string' || typeof o[key] === 'number')
        return (
          <Row key={i}>
            <Label>{key}</Label>
            <Display o={o[key]} />
          </Row>
        )
      if (typeof o[key] === 'boolean')
        return (
          <Row key={i}>
            <Label>{key}</Label>
            <Display o={o[key]} />
          </Row>
        )

      if (Array.isArray(o[key]))
        return o[key].map((o, i, a) => (
          <Row key={i}>
            {i === 0 && <hr />}
            <Label bool>Item {i}</Label>
            <Display o={o} />
            <hr />
          </Row>
        ))

      const mkeys = Object.keys(o[key])
      if (mkeys.length < 4)
        return mkeys.map((mkey, i) => (
          <Row key={i}>
            <Label>{`${key} ${mkey.replace(key, '')}`}</Label>
            <Display o={o[key][mkey]} />
          </Row>
        ))
      else
        return (
          <Row key={i}>
            <Label>{key}</Label>
            <Display o={o[key]} />
          </Row>
        )
    })
  }

  return (
    <section
      style={{
        // boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
        borderRadius: 4,
        boxShadow: '0px 2px 3px #ddd',
        background: '#fff',
        padding: '2px 16px 8px 16px',
        marginBottom: 16,
        fontFamily: 'Arial, Helvetica, sans-serif',
      }}
      className={`panel${hidden ? ' hidden' : ''}`}
      onClick={() => setHidden(state => !state)}
    >
      <h3>{title}</h3>
      {isValidating && <LinearProgress />}
      {data.map((o, i) => (
        <div key={i}>
          {i > 0 && <hr />}
          <Display o={o} />
        </div>
      ))}
      {children}
    </section>
  )
}
