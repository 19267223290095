import CircularProgress from '@material-ui/core/CircularProgress'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import React from 'react'
import useSWR from 'swr'
import fetcher from 'utils/api'

export default function DropDown({
  queryString,
  values,
  labels,
  display,
  ...rest
}) {
  const query = useSWR(queryString, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  })

  // const q =
  //   `InventoryTypes?$select=Code,Description&$filter=Obsolete eq 0&$top=50&$count=true` +
  //   `InventoryStatus?$select=Description,StatusCode&$filter=Obsolete eq 0&$top=50&$count=true`

  const { data, isValidating, error } = query

  const d = display
    ? display
    : item => (
        <MenuItem key={item?.[values]} value={item?.[values]}>
          {item?.[labels]}
        </MenuItem>
      )
  // if (!data?.[0]?.hasOwnProperty(values) || !data?.[0]?.hasOwnProperty(labels))
  //   console.log('choose one of these', data)

  return (
    <TextField
      {...rest}
      select
      InputProps={
        isValidating && {
          endAdornment: (
            <InputAdornment position="end">
              <CircularProgress size={20} />
            </InputAdornment>
          ),
        }
      }
    >
      {Array.isArray(data) && data.map(item => d(item))}
    </TextField>
  )
}
