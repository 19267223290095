import { createMuiTheme } from '@material-ui/core/styles'

const { spacing, breakpoints } = createMuiTheme()

export default createMuiTheme({
  palette: {
    primary: {
      main: '#000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#5aac44',
    },
  },
  typography: {
    h1: { fontSize: '2rem' },
    h2: { fontSize: '1.8rem' },
    h3: { fontSize: '1.6rem' },
    h4: { fontSize: '1.4rem' },
    h5: { fontSize: '1.2rem' },
    h6: { fontSize: '1rem' },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          height: '100%',
          // display: 'flex',
          // flexDirection: 'column',
        },
        body: {
          height: '100%',
        },
        '#root': {
          height: '100%',
        },
        a: { color: 'inherit' },
      },
    },
    MuiListItem: {
      root: {
        // borderBottom: '1px solid #aaa',
      },
    },
    MuiPaper: {
      root: {
        position: 'relative',
        marginBottom: spacing(2),
      },
    },
    MuiLinearProgress: {
      root: {
        opacity: 0.5,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
      },
    },
    MuiBottomNavigation: {
      root: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    MuiFab: {
      root: {
        position: 'fixed',
        bottom: 80,
        right: 16,
        zIndex: 1,
      },
    },
    MuiTextField: {
      outlined: {
        background: ['#fff', '!important'],
      },
    },
    MuiOutlinedInput: {
      root: {
        background: ['#fff', '!important'],
      },
    },
    MuiStepper: {
      root: {
        paddingLeft: 0, // spacing(1),
        paddingRight: 0, // spacing(1),
        background: 'transparent',
      },
    },
    MuiDialogActions: {
      root: {
        padding: spacing(3),
        '& > button:only-child': {
          marginLeft: 'auto',
        },
      },
    },
    MuiContainer: {
      disableGutters: {
        marginLeft: -spacing(2),
        marginRight: -spacing(2),
        width: `calc(100% + ${spacing(4)}px)`,
        overflowX: 'hidden',
        [breakpoints.up('sm')]: {
          marginLeft: -spacing(3),
          marginRight: -spacing(3),
          width: `calc(100% + ${spacing(6)}px)`,
        },
      },
    },
    MuiTabs: {
      root: {
        marginBottom: spacing(2),
      },
    },
    MuiMenuItem: {
      root: {
        whiteSpace: 'wrap',
      },
    },
  },
  props: {
    MuiFab: {
      color: 'primary',
    },
    MuiContainer: {
      maxWidth: 'sm',
    },
    MuiSvgIcon: {
      role: null,
    },
    MuiLink: {
      component: 'span',
      underline: 'always',
    },
    MuiTextField: {
      variant: 'outlined',
    },
    MuiSelect: {
      variant: 'outlined',
    },
    MuiButton: {
      variant: 'contained',
      size: 'large',
      color: 'primary',
    },
    MuiDialog: {
      fullWidth: true,
    },
  },
})
