import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

export default function ListView({
  query,
  display,
  notFound = 'No items found',
}) {
  const { data, error, isValidating } = query

  const [items, setItems] = React.useState(data)

  React.useEffect(() => data && setItems(data), [data])

  if (error) return console.error(error) || <p>Error!</p>

  const SkeletalListItem = ({ count }) =>
    Array(count)
      .fill(null)
      .map((n, i, a) => (
        <ListItem key={i} divider={i + 1 < a.length}>
          <ListItemText
            primary={<Skeleton type="text" />}
            secondary={<Skeleton type="text" />}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete">
              <Skeleton type="circle" width="48" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))

  const RenderList = () => {
    if (Array.isArray(items))
      if (items.length > 0) return items.map((item, i) => display(item))
      else
        return (
          <Box px={2}>
            <Typography>{notFound}</Typography>
          </Box>
        )
    else if (isValidating) return <SkeletalListItem count={3} />
    return null
  }

  return (
    <>
      {isValidating && <LinearProgress />}
      <List>
        <RenderList />
      </List>
    </>
  )
}
