import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Add from '@material-ui/icons/Add'
import Edit from '@material-ui/icons/Edit'
import ListView from 'components/ListView'
import QuoteLineCreate from 'dialogs/QuoteLineAdd'
import React from 'react'
import { Link, Route, useParams, useRouteMatch } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'
import * as utils from 'utils/utils'

const useStyles = makeStyles(theme => ({
  root: {},
}))

export default function CompanyQuote({ quoteId }) {
  const { divisionId, problemId } = useParams()
  const { path, url } = useRouteMatch()
  const classes = useStyles()

  const queryLines = useSWR(
    () =>
      quoteId
        ? `QuoteLines?
            $filter=StatusFlag ne 'D' and QuoteId eq ${quoteId}&
            $expand=ProductItem(
              $expand=Category($select=CategoryId,Description)
            )
          `
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  return (
    <>
      <Link to={`${url}/editline/new`}>
        <Fab aria-label="add" className={classes.fab}>
          <Add />
        </Fab>
      </Link>

      <ListView
        query={queryLines}
        display={item => (
          <Link to={`${url}/editline/${item.LineId}`}>
            <ListItem>
              <ListItemText
                primary={item.ProductItem?.Description}
                secondary={`${item.DecimalQuantity} x ${utils.toPounds(
                  item.Price
                )}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  // onClick={() => handleDelete(item.LineId)}
                >
                  <Edit />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </Link>
        )}
      />
      <Route path={`${path}/editline/:lineId`}>
        <QuoteLineCreate onAfterSubmit={queryLines.mutate} quoteId={quoteId} />
      </Route>
    </>
  )
}
