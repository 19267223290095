import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import Paper from '@material-ui/core/Paper'
import { ArrowBack, ArrowForward, Home } from '@material-ui/icons'
import Error from 'components/Error'
import Field from 'fields/Field'
import {
  FieldProvider,
  useFieldDispatch,
  useFieldState,
} from 'fields/FormFieldProvider'
import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import useSWR from 'swr'
import fetcher, * as api from 'utils/api'

export default function ProblemInfo({ nextPath }) {
  const { divisionId, problemId } = useParams()
  const history = useHistory()
  // const [newProblem, setNewProblem] = React.useState({})

  const { data, error, isValidating, mutate } = useSWR(
    () =>
      problemId
        ? `Problems(${problemId})?$select=Description&$expand=Status($select=Description),LastUpdatedByUser($select=UserName),Division($select=Name)`
        : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (error) return <Error error={error} />

  if (!data) return <LinearProgress />

  return (
    <>
      <Box component={Paper} p={2} align="center">
        <Typography gutterBottom variant="h4">
          Congratulations on a job well done{' '}
          {data[0].LastUpdatedByUser.UserName.split(' ')[0]}.
        </Typography>
        <Typography gutterBottom>
          Problem <b>{data[0].Description}</b> has been set to{' '}
          <b>{data[0].Status.Description}</b> and a record of your successes has
          been stored to ProspectSoft.
        </Typography>
      </Box>
      <Box mt={4} align="center">
        <Button onClick={() => history.push(`/division/${divisionId}/info`)}>
          <ArrowBack /> Back to {data[0].Division.Name}
        </Button>
      </Box>
      <Box mt={4} align="center">
        <Button onClick={() => history.push('/')}>
          Go to home <Home />
        </Button>
      </Box>
    </>
  )
}

// const get =

// const fields = [
//   'AddressId',
//   'Description',
//   'InventoryId',
//   'PipelineId',
//   'ProblemId',
//   'SituationSummary',
//   'StatusDetailId',
//   'StatusId',
//   'Type1Id',
//   'Type2Id',
//   'Type3Id',
//   'Type4Id',
//   'Type5Id',
// ]

// const editableFields = [
//   'SourceId',
//   'Description',
//   'InventoryId', // ?
//   'SituationSummary',
//   'StatusId',
//   'Type3Id',
// ]
//&$filter=Type1Id eq 'TECHNI'
