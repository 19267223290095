const callout = '28392f'
const ppm = '8d8db8'
const install = '927d05'
const warranty = 'WARANT'
const revisit = 'a3dc06'

// prettier-ignore
export const siteChecklist = [
  { name: 'Visit details', type: 'heading', type3Id: [callout, revisit, warranty, ppm, install] },

  { name: 'Summary of work', type: 'textarea', type3Id: [callout, revisit, warranty, ppm, install] },
  { name: 'Likely cause', type: 'text', type3Id: [callout, revisit, warranty, ppm, install] },

  { name: 'Espresso machine checklist', type: 'heading' },

  { name: 'Boiler filled', type: 'checkbox', type3Id: [install] },
  { name: 'Groups bled ok', type: 'checkbox', type3Id: [install] },
  { name: 'Safety valve check passed', type: 'checkbox', type3Id: [install] },
  { name: 'Boiler pressure ok', type: 'checkbox', type3Id: [install, ppm, callout, revisit, warranty] },
  { name: 'Group temp ok', type: 'checkbox', type3Id: [install, ppm] },
  { name: 'Water level ok', type: 'checkbox', type3Id: [install, ppm, callout, revisit, warranty] },
  { name: 'Extraction pressure ok', type: 'checkbox', type3Id: [install, ppm, callout, revisit, warranty] },
  { name: 'Flow rate ok', type: 'checkbox', type3Id: [ppm] },
  { name: 'Volumetrics set ok', type: 'checkbox', type3Id: [install, ppm]},
  { name: 'Change seals and screens', type: 'checkbox', type3Id: [ppm] },

  { name: 'Water softener', type: 'heading', type3Id: [install, ppm] },
  { name: 'Total hardness (ºd GH)', type: 'number', min: 0, max: 20, init: 1, type3Id: [install, ppm]},

  { name: 'Espresso grinder checklist', type: 'heading', type3Id: [ppm] },
  { name: 'Deep grinder clean', type: 'checkbox', type3Id: [ppm] },
  { name: 'Check burrs ok', type: 'checkbox', type3Id: [ppm] },
  { name: 'Check clump crusher ok', type: 'checkbox', type3Id: [ppm] },

  { name: 'Shop grinder checklist', type: 'heading', type3Id: [ppm] },  
  { name: 'Check burrs ok 2', label: "Check burrs ok", type: 'checkbox', type3Id: [ppm] },
  { name: 'Zero grind adjustment', type: 'checkbox', type3Id: [ppm] },

  { name: 'Final info', type: 'heading', type3Id: [install, ppm, callout, revisit, warranty] },
  { name: 'Additional notes or actions', type: 'textarea', type3Id: [install, ppm, callout, revisit, warranty]},

  // { //   name: 'Photo of work completed 1',
  //   type: 'photo',
  // },
  // { //   name: 'Photo of work completed 2',
  //   type: 'photo',
  // },
  // { name: 'Final status', type: 'select', 
  //   options: [
  //     { name: 'Resolved', value: 0 },
  //     { name: 'Unresolved', value: 0 },
  //     { name: 'Aborted', value: 0 },
  //   ],
  // },
]

export const siteChecklistSectioned = siteChecklist
  .reduce(
    (arr, item) =>
      item.type === 'heading'
        ? [...arr, [item]]
        : [...arr.slice(0, arr.length - 1), [...arr[arr.length - 1], item]],
    [[]]
  )
  .filter(a => a.length)

export const sectionChecklistFields = siteChecklist =>
  siteChecklist
    .reduce(
      (arr, item) =>
        item.type === 'heading'
          ? [...arr, [item]]
          : [...arr.slice(0, arr.length - 1), [...arr[arr.length - 1], item]],
      [[]]
    )
    .filter(a => a.length)

export const openStati = [
  '8f85d1',
  'AWTRET',
  'LOGGED',
  'NEW',
  'PSDTOS',
  'WAITIN',
]

export const closedStati = [
  'CANCLD',
  'CLONRT',
  'CLOSDS',
  'CLOSED',
  'FLDTOR',
  'PRBGNA',
  'PSDTOT',
  'RETACC',
  'RETREJ',
  'ABRT-A',
]

const obseleteStati = ['RETNOI', 'RETREC', 'WORKON', 'WTCUST']
