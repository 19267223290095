import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Field from 'fields/Field'
import { FieldProvider, useFieldState } from 'fields/FormFieldProvider'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import useSWR from 'swr'
import createPersistedState from 'use-persisted-state'
import fetcher from 'utils/api'
import { siteChecklist } from 'utils/constants'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ArrowForward } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'

const useChecklistsState = createPersistedState('checklists')

const getInit = (fields, type3Id) =>
  Array.isArray(fields)
    ? fields
        .filter(field => field.type !== 'heading')
        .filter(
          field =>
            field.type3Id !== undefined &&
            field.type3Id.some(id => id === type3Id)
        )
        .reduce(
          (obj, field) => ({
            ...obj,
            [field.name]: field.init || field.type === 'checkbox' ? false : '',
          }),
          {}
        )
    : Object.keys(fields)
        .filter(key => fields[key].type !== 'heading')
        .filter(
          key =>
            fields[key].type3Id === undefined ||
            fields[key].type3Id.some(id => id === type3Id)
        )
        .reduce(
          (obj, key) => ({
            ...obj,
            [fields[key].name]:
              fields[key].init || fields[key].type === 'checkbox' ? false : '',
          }),
          {}
        )

export function ProblemChecklist({ siteChecklistSections, nextPath }) {
  const history = useHistory()
  const { divisionId, problemId } = useParams()

  const state = useFieldState()
  // const dispatch = useFieldDispatch()
  // use the below for cleaner saving etc
  // https://github.com/kripod/react-hooks/tree/master/packages/web-api-hooks
  const [checklists, setChecklists] = useChecklistsState([])

  const handleSave = () => {
    if (problemId) {
      setChecklists(checklists => [
        ...checklists.filter(checklist => checklist.problemId !== problemId),
        { problemId, ...state },
      ])

      // const savedChecklist = checklists.filter(checklist => checklist.problemId !== problemId)
      setTimeout(() => history.push(nextPath))
      console.log('STATE', state)
    }
  }

  return (
    <>
      {siteChecklistSections.map(siteChecklist => (
        <Box component={Paper} p={2}>
          {siteChecklist.map(
            ({ init, type, name, label, type3Id, ...field }) => (
              <Field
                key={name}
                name={name}
                label={label || name}
                {...{ ...field, [type]: true }}
              />
            )
          )}
        </Box>
      ))}
      <Box mt={2} align="center">
        <Button onClick={handleSave}>
          Save and next
          <ArrowForward />
        </Button>
      </Box>
    </>
  )
}

export default function ProblemChecklistProvider({ ...rest }) {
  const [checklists, setChecklists] = useChecklistsState([])

  const { problemId, checklistId } = useParams()

  const { data, error, isValidating, mutate } = useSWR(
    () =>
      problemId
        ? `Problems(${problemId})?$select=ProblemId,Type3Id&$expand=Source,Type3`
        : null,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )
  const problem = data?.[0]
  console.log(problem)
  if (!problem)
    return (
      <Paper>
        <LinearProgress />
      </Paper>
    )

  const { Type3Id, Source, Type3 } = problem
  // prev

  const defaultState = getInit(siteChecklist, Type3Id)

  const savedState =
    checklists.find(checklist => checklist.problemId === problemId) || {}

  const initState = Object.keys(defaultState).reduce(
    (state, key) => ({
      ...state,
      [key]:
        savedState[key] !== undefined ? savedState[key] : defaultState[key],
    }),
    {}
  )

  const siteChecklistSections = siteChecklist
    .filter(
      field =>
        field.type3Id === undefined || field.type3Id.some(id => id === Type3Id)
    )
    .reduce(
      (arr, item) =>
        item.type === 'heading'
          ? [...arr, [item]]
          : [...arr.slice(0, arr.length - 1), [...arr[arr.length - 1], item]],
      [[]]
    )
    .filter(a => a.length)

  // const allowedFields = siteChecklist.filter(
  //   ({ type3Id }) => type3Id === undefined || type3Id.some(id => id === Type3Id)
  // )

  // React.useEffect(() => {}, [Type3Id])

  return (
    <FieldProvider initState={initState}>
      <Typography variant="h3" gutterBottom>
        {Source.Description} {Type3.Description}
      </Typography>

      <ProblemChecklist
        siteChecklistSections={siteChecklistSections}
        type3Id={Type3Id}
        {...rest}
      />
    </FieldProvider>
  )
}
