import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FolderIcon from '@material-ui/icons/Folder'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import { Link, useParams } from 'react-router-dom'
import fetcher from 'utils/api'
import useSWR from 'swr'
import Layout from 'components/Layout'
import * as utils from 'utils/utils'
import Field from 'fields/Field'

const productItemIds = [
  '15030079',
  '15030109',
  '15080009',
  '300681',
  'A501401',
  'BWTB0XL',
  'BWTB2XL',
  'BWTHEAD',
  'FS000IMB02',
  'H101702',
  'JAG0371',
  'JAG0386',
  'JAG0508',
  'JAG0568',
  'JAG0612',
  'JAG0771',
  'JAG1013',
  'JAG1031',
  'JAG1049',
  'JAG4301',
  'JAG4828',
  'JAG5345',
  'LAMA17',
  'M1BURRS',
  'MISC0003',
  'INSTA001',
  'PVI',
  'SERVICE1',
  'SERVICE2',
  'INSTA005',
  'BCM003',
]

const select = [
  'Description',
  'SellingPrice',
  'SellDecimals',
  'ProductItemId',
  'QuantityDecimal',
].join(',')

const filter = productItemIds.reduce(
  (string, id, i) => `${string}${i > 0 ? ' or ' : ''}ProductItemId eq '${id}'`,
  ''
)

export const productItemsQuerystring = `ProductItems?$select=${select}&$filter=${filter}`

export const productItemsSearchQuerystring = search =>
  `ProductItems?$select=${select}&$filter=${filter} and contains(Description,'${search}')`

export function ProductItemsDropdown(props) {
  return (
    <Field
      dropdown
      name="ProductItemId"
      label="Product"
      values="ProductItemId"
      labels="Description"
      queryString={`ProductItems?$select=${select}&$filter=${filter}`}
      {...props}
    />
  )
}

export default function ProductItemsDropdownOld(props) {
  const { data, error, isValidating } = useSWR(
    `ProductItems?$select=${select}&$filter=${filter}`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  )

  if (error) console.error(error)
  return (
    <TextField {...props} select>
      {Array.isArray(data) &&
        data.map(({ Description, ProductItemId }) => (
          <MenuItem key={ProductItemId} value={ProductItemId}>
            {Description}
          </MenuItem>
        ))}
    </TextField>
  )
}
