import Fab from '@material-ui/core/Fab'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import AddIcon from '@material-ui/icons/Add'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ListView from 'components/ListView'
import DivisionEquipmentAdd from 'dialogs/DivisionEquipmentAdd'
import React from 'react'
import {
  Link,
  Route,
  useParams,
  useRouteMatch,
  useHistory,
} from 'react-router-dom'
import useSWR from 'swr'
import fetcher from 'utils/api'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ArrowForward from '@material-ui/icons/ArrowForward'

export default function DivisionEquipment({ nextPath }) {
  const { divisionId } = useParams()
  const { path, url } = useRouteMatch()
  const history = useHistory()

  const query = useSWR(
    () =>
      divisionId
        ? `Inventories?
            $expand=
              Product($select=Description,OperatingCompanyCode,ProductItemId),
              Status($select=Description,StatusCode,StatusFlag)&
            $select=Commissioned,Decommissioned,Description,DivisionId,InventoryId,SerialNumber,StatusCode,StatusFlag,TypeId,Product,Status&
            $filter=DivisionId eq ${divisionId} and StatusCode ne 'WRIOFF'&
            $orderby=InventoryId desc&
            $top=10&
            $skip=0&
            $count=true`
            .replace(/\n/g, '')
            .replace(/  +/g, '')
        : null,
    fetcher
  )

  return (
    <>
      <Link to={`${url}/edit/new`}>
        <Fab aria-label="add">
          <AddIcon />
        </Fab>
      </Link>
      <Paper>
        <ListView
          query={query}
          display={item => (
            <Link to={`${url}/edit/${item?.InventoryId}`}>
              <ListItem>
                <ListItemText
                  primary={item?.Product?.Description || item?.Description}
                  secondary={item?.Status?.Description}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <ArrowForwardIos />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </Link>
          )}
        ></ListView>
      </Paper>
      <Route path={`${path}/edit/:inventoryId`}>
        <DivisionEquipmentAdd onAfterSubmit={query.mutate} />
      </Route>
      {nextPath && (
        <Box mt={2} align="center">
          <Button onClick={() => history.push(nextPath)}>
            Next <ArrowForward />
          </Button>
        </Box>
      )}
    </>
  )
}
